/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* stylelint-disable declaration-block-no-shorthand-property-overrides */
  font-size: 100%;
  font: inherit;
  /* stylelint-enable declaration-block-no-shorthand-property-overrides */
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: " ";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--magic-background, #fbfbfb);
  font: normal normal var(--magic-font-size, 14px) var(--magic-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-weight: var(--magic-font-weight, 400);
  color: var(--magic-text, #747474);
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
  overflow-x: hidden;
}
body:-webkit-full-screen {
  width: 100%;
}
body.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
*::after, *::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 0.5em;
  font-family: var(--magic-header-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-weight: var(--magic-header-font-weight, 700);
  color: inherit;
  line-height: 1;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3,
h4 {
  font-size: 18px;
}

p {
  margin: 0 0 1em;
  font-size: 12px;
}

strong,
b {
  font-weight: var(--magic-caption-font-weight, 600);
}

em,
i {
  font-style: italic;
}

a {
  outline: none;
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button:hover, button:focus, button:active {
  outline: none;
}

input,
textarea,
button,
select {
  font-family: var(--magic-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-size: 100%;
  color: inherit;
  line-height: 1;
}

input[type=date]::-webkit-date-and-time-value {
  margin-top: 6px;
}

input[type=date]::-webkit-clear-button,
input[type=date]::-webkit-inner-spin-button {
  margin: 0;
  opacity: 0;
}

input[type=date]::-webkit-calendar-picker-indicator {
  margin-right: 30px;
  width: 12px;
  height: 12px;
  color: rgba(var(--magic-light, #fff), 1);
}
input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: rgba(var(--magic-light, #fff), 0);
}
input[type=date]::-webkit-calendar-picker-indicator:active {
  color: rgba(var(--magic-light, #fff), 1);
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-credentials-auto-fill-button {
  background: transparent;
}

::-webkit-contacts-auto-fill-button {
  background: transparent;
}

::-webkit-caps-lock-indicator {
  content: none;
}

video {
  background-color: rgba(var(--magic-dark, #000), 0);
}

div:focus,
label:focus {
  outline: none;
}

label {
  cursor: inherit;
}

@-moz-document url-prefix() {
  img {
    color: rgba(var(--magic-light, #fff), 0);
  }
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(var(--magic-dark, #000), 0);
}

div div[role=presentation] iframe {
  animation: none !important;
  transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
}
div div[role=presentation] div {
  transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
}
@media (max-height: 540px) and (max-width: 920px) and (orientation: landscape) {
  div div[role=presentation] iframe {
    transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
  }
  div div[role=presentation] div {
    transform: translate3d(0, var(--magic-bottom-navigation-height, 64px), 0);
  }
}
@media (max-height: 920px) and (max-width: 540px) and (orientation: portrait) {
  div div[role=presentation] iframe {
    transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
  }
  div div[role=presentation] div {
    transform: translate3d(0, var(--magic-bottom-navigation-height, 64px), 0);
  }
}
@media (min-width: 1024px) {
  div div[role=presentation] iframe {
    transform: none;
  }
  div div[role=presentation] div {
    transform: none;
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin-animation {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@keyframes scale-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
html {
  --magic-primary: #00eaab;
  --magic-secondary: #292d39;
  --magic-tertiary: #231f20;
  --magic-background: #fbfbfb;
  --magic-text: #292d39;
  --magic-text-primary: #292d39;
  --magic-caption: #292d39;
  --magic-primary-contrast: var(--magic-light, #fff);
  --magic-secondary-contrast: var(--magic-light, #fff);
  --magic-success: #009b6e;
  --magic-error: #ff2334;
  --magic-alert: #ff9f32;
  --magic-alert-contrast: var(--magic-light, #fff);
  --magic-light-contrast: #231f20;
  --magic-field-border: #c6c6c6;
  --magic-header: #231f20;
  --magic-badge-background: #00eaab;
  --magic-medium: #292d39;
  --magic-button-primary-darker: #04dba0;
  --magic-button-medium-darker: #262a35;
  --magic-button-light-darker: #efefef;
  --magic-button-success-darker: #039167;
  --magic-button-error-darker: #ef2131;
  --magic-button-alert-darker: #f0952f;
  --magic-button-primary-transparent: rgba(0, 234, 171, 0.125);
  --magic-button-disabled-darker: #e4e4e4;
  --magic-button-alert-darker: #f0952f;
  --magic-button-disabled-transparent: #e4e4e4;
  --magic-button-light-transparent: transparent;
  --magic-checkbox-background: #00eaab;
  --magic-checkbox-border: #292d39;
  --magic-checkbox-hover-border: #00eaab;
  --magic-checkbox-focus-border: #00eaab;
  --magic-checkbox-checked-border: #00eaab;
  --magic-checkbox-disabled-border: #e4e4e4;
  --magic-checkbox-disabled-label: #e4e4e4;
  --magic-checkbox-disabled-background: #e4e4e4;
  --magic-radio-border: #231f20;
  --magic-radio-hover-border: #00eaab;
  --magic-radio-focus-border: #00eaab;
  --magic-radio-checked-border: #00eaab;
  --magic-radio-background: #00eaab;
  --magic-switch-checked-background: #00eaab;
  --magic-switch-hover-border: #00eaab;
  --magic-font-family: "Brandon Grotesque";
  --magic-header-font-family: "Brandon Grotesque";
  --magic-dropdown-padding-vertical: 12px;
  --magic-gradient-1: #00eaab linear-gradient(135deg, #00eaab, #241831);
  --magic-step-progress-bar-color: rgba(0, 234, 171, 0.3);
  --magic-account-menu-background: #212121;
  --magic-coin-balance-icon-color: #00eaab;
  --magic-shop-item-icon-color: #00eaab;
  --magic-shop-balance-icon-color: #00eaab;
  --magic-game-navigation-landscape-width: 98px;
  --magic-element-default-width: 344px;
  --magic-promotions-accordion-toggle-background: #231f20;
  --magic-offer-card-size: var(--magic-element-default-width, 288px);
}

html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *::before,
html.color-theme-in-transition *::after {
  transition: all 0.75s;
  transition-delay: 0;
}

html[data-theme=dark] {
  --magic-tertiary: black;
  --magic-tertiary-contrast: #b0b0b0;
  --magic-disabled: #8b8b8b;
  --magic-background: #1a1a1a;
  --magic-background-light: #343434;
  --magic-background-dark: #1a1a1a;
  --magic-text: #b0b0b0;
  --magic-caption: #c6c6c6;
  --magic-header: #c6c6c6;
  --magic-shadow: rgba(0, 0, 0, 0.75);
  --magic-shadow-light: rgba(0, 0, 0, 0.45);
  --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
  --magic-input-border: #4e4e4e;
  --magic-dropdown-border: #4e4e4e;
  --magic-selects-border: #4e4e4e;
  --magic-form-field-message-background: #252525;
  --magic-password-indicator-background: #252525;
}

@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) {
    --magic-tertiary: #009e73;
    --magic-tertiary-contrast: #b0b0b0;
    --magic-disabled: #8b8b8b;
    --magic-background: #1a1a1a;
    --magic-background-light: #343434;
    --magic-background-dark: #1a1a1a;
    --magic-text: #b0b0b0;
    --magic-caption: #c6c6c6;
    --magic-header: #c6c6c6;
    --magic-shadow: rgba(0, 0, 0, 0.75);
    --magic-shadow-light: rgba(0, 0, 0, 0.45);
    --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
    --magic-input-border: #4e4e4e;
    --magic-dropdown-border: #4e4e4e;
    --magic-selects-border: #4e4e4e;
    --magic-form-field-message-background: #252525;
    --magic-password-indicator-background: #252525;
  }
}
body {
  background-color: var(--magic-light, #fff);
}

.game-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 72px);
  grid-gap: var(--magic-space, 16px);
  justify-content: space-between;
  padding: 0 calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px)) var(--magic-space--small, 8px);
}
.game-list .game-wrapper {
  width: var(--magic-mini-games-thumbnail-width, 72px);
  height: var(--magic-mini-games-thumbnail-height, 72px);
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  display: flex;
  align-items: center;
  margin: var(--magic-tag-space-outer, var(--magic-space--x-small, 4px));
  padding: var(--magic-tag-space-inner, 0 12px);
  border-radius: var(--magic-tag-border-radius, 24px);
  min-height: var(--magic-tag-height, 24px);
  background: var(--magic-tag-background, #52ae32);
  font-size: var(--magic-tag-text-size, 12px);
  font-weight: 600;
  color: var(--magic-tag-text-color, #fff);
  line-height: 18px;
}
.tag--new {
  background: var(--magic-tag-background, #52ae32);
}
.tag--hot {
  background: var(--magic-tag-hot-background, #e94f3d);
}

.game {
  position: relative;
  width: 100%;
  text-align: center;
}
.game .link {
  display: block;
  position: relative;
  box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0), 0 0 1px 0 rgba(10, 31, 68, 0);
  transform: scale(1);
  transition: transform var(--magic-duration, 0.3s) ease, box-shadow var(--magic-duration, 0.3s) ease;
  will-change: transform, box-shadow;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: var(--magic-game-border-radius, var(--magic-border-radius, 4px));
}
.game .link::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--magic-game-border-radius, var(--magic-border-radius, 4px));
  width: 100%;
  height: 100%;
  background-color: var(--magic-dark, #000);
  opacity: 0;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  z-index: 1;
}
.game .link--desktop:hover {
  transform: scale(1.1);
  box-shadow: var(--magic-shadow-3, 0 8px 10px 0 var(--magic-shadow-light, rgba(10, 31, 68, 0.1)), 0 0 1px 0 var(--magic-shadow-lighter, rgba(10, 31, 68, 0.08)), 0 0 0 0.2px var(--magic-shadow-lighter, rgba(10, 31, 68, 0.08)));
}
.game .link--desktop:hover::before {
  opacity: 0.25;
}
.game .link--desktop:hover .tag {
  opacity: 0;
}
.game .link--desktop:hover .icon {
  opacity: 1;
  transform: scale(0.90909);
  transition: opacity var(--magic-duration, 0.3s) calc(var(--magic-delay, 0.3s) / 2) ease, transform var(--magic-duration, 0.3s) calc(var(--magic-delay, 0.3s) / 2) ease;
}
.game .game-tag {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  z-index: 2;
  max-width: var(--magic-space--x-large, 64px);
}
.game .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: calc(-1 * (var(--magic-game-play-icon-size, 44px) / 2)) 0 0 calc(-1 * (var(--magic-game-play-icon-size, 44px) / 2));
  padding: 0;
  width: var(--magic-game-play-icon-size, 44px);
  height: var(--magic-game-play-icon-size, 44px);
  color: var(--magic-light, #fff);
  opacity: 0;
  transform: scale(0.8125);
  transition: opacity var(--magic-duration, 0.3s) 0s ease, transform var(--magic-duration, 0.3s) 0s ease;
  will-change: opacity, transform;
  z-index: 2;
}

.wrapper {
  width: var(--magic-mini-games-thumbnail-width, 72px);
  height: var(--magic-mini-games-thumbnail-height, 72px);
}

.thumbnail {
  display: block;
  position: relative;
  padding: 0;
  border-radius: var(--magic-border-radius, 4px);
  border-collapse: separate;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.thumbnail .image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.empty-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-results-container .header {
  font-size: 18px;
  font-weight: 600;
  color: var(--magic-caption, #161616);
  margin-bottom: 8px;
}
.empty-results-container .empty-icon {
  border-radius: 100px;
  background: var(--magic-primary, #0c66ff);
  width: 40px;
  height: 40px;
  padding: calc(var(--magic-space--small, 8px) + var(--magic-space--xx-small, 2px));
  margin-bottom: var(--magic-space, 16px);
}

.search-input-container {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  margin-bottom: var(--magic-space--small, 8px);
  padding: 0 calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px)) var(--magic-space--small, 8px);
  background: var(--magic-light, #fff);
}
.search-input-container .input {
  width: 100%;
}
.search-input-container .search-icon {
  position: absolute;
  top: calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  right: calc(var(--magic-space, 16px) + var(--magic-space--x-small, 4px));
  opacity: 0;
  animation: fade-in-animation var(--magic-duration, 0.3s) var(--magic-delay, 0.3s) 1 forwards;
  will-change: opacity;
  pointer-events: none;
}
.search-input-container .button {
  opacity: 1;
  will-change: opacity;
  border: none;
  background: transparent;
  position: absolute;
  top: calc(var(--magic-space, 16px) - var(--magic-space--xx-small, 2px));
  right: calc(var(--magic-space, 16px) + var(--magic-space--small, 8px));
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
}
.search-input-container .button.fade-in-enter-active, .search-input-container .button.fade-in-leave-active {
  transition: opacity var(--magic-duration, 0.3s) ease;
}
.search-input-container .button.fade-in-enter, .search-input-container .button.fade-in-leave-to {
  opacity: 0;
}
.search-input-container .button:hover, .search-input-container .button:focus {
  opacity: 0.65;
}
@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon {
  display: block;
}
.icon.icon-play {
  width: 44px;
  height: 44px;
  background-image: url("/img/icons/mini-lobby-play.svg");
}
.icon.icon-close {
  width: 13px;
  height: 13px;
  background-image: url("/img/icons/mini-lobby-close.svg");
}
.icon.icon-search {
  width: 20px;
  height: 20px;
  background-image: url("/img/icons/mini-lobby-search.svg");
}
.icon.icon-empty-search {
  width: 21px;
  height: 21px;
  background-image: url("/img/icons/mini-lobby-empty-search.svg");
}

:host {
  outline: none;
}

* {
  box-sizing: border-box;
}
*::after, *::before {
  box-sizing: border-box;
}

.input {
  background: var(--magic-input-background, var(--magic-background, #fbfbfb));
  font-size: 14px;
  display: block;
  padding: var(--magic-input-padding-vertical, 14px) var(--magic-input-padding-horizontal, 16px);
  border: none;
  border-radius: var(--magic-border-radius, 4px);
  width: 100%;
  height: var(--magic-input-height, 44px);
  box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4));
  color: var(--magic-caption, #161616);
  line-height: var(--magic-input-line-height, var(--magic-space, 16px));
  transition: box-shadow var(--magic-duration, 0.3s) ease;
  will-change: box-shadow;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.input:focus {
  box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff);
  outline: none;
}
.input:-ms-input-placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
}
.input::-ms-input-placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
}
.input::placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
}
.input::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
  line-height: normal;
}
.input::-moz-placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
  opacity: 1;
}
.input::-ms-input-placeholder {
  font-size: 14px;
  color: var(--magic-text-lighter, #c6c6c6);
}
.input:-webkit-autofill {
  font-size: 14px;
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4)), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
  -webkit-text-fill-color: var(--magic-text, #747474);
  -webkit-animation-name: autofill-animation;
  -webkit-animation-fill-mode: both;
  line-height: normal;
  -webkit-transition: background-color 9999s 0s linear;
  transition: background-color 9999s 0s linear;
  will-change: background-color;
}
.input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4)), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
@keyframes autofill-animation {
  to {
    background-color: var(--magic-input-background, var(--magic-background, #fbfbfb));
    color: var(--magic-text, #747474);
  }
}